import React from "react";
import { Container, PrimaryBtn } from "components/library";
import { Link } from "gatsby";
import { Chip } from "components/common/Chip";
import { ArrowRight } from "react-feather";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { NotionArticle, useArticle } from "utils";
import moment from "moment";
import { BLOG_BASE_URL } from "variables"

interface P {
  article: NotionArticle
}

export const FeaturedArticle = (article: P) => {
  const {
    title,
    excerpt,
    publishedDate,
    timeToRead,
    author,
    categories,
    featuredImage,
  } = useArticle(article);

  const authorPicture = author.image === "" ? "images/profile-pic-placeholder.png" : author.image;
  const internalLink = useArticle(article).link;
  const fullInternalLink = `${BLOG_BASE_URL}/${internalLink}`
  const externalLink = useArticle(article).externalLink;

  const link = `${externalLink === "" ? fullInternalLink : externalLink}`

  const getWordCount = (s: string) => {
    return s.trim().split(/\s+/).length;
  }

  const { t } = useTranslation()

  return <Container>
    <div className="flex flex-col lg:flex-row rounded-lg lg-text-inherit overflow-hidden shadow-md bg-white w-full h-full hover:bg-neutral-100">
      <Link to={link} className="w-full h-auto">
        <img src={featuredImage} alt="Featured image" className="w-full h-full object-contain" />
      </Link>
      <div className='flex flex-col h-full bg-white w-full pt-12 pb-12 pl-6 pr-6'>
        <div>
          <p className='text-[#9FA8B7] pb-8'>
            <p>{moment(publishedDate).format("MMMM Do YYYY")} • {timeToRead}</p>
          </p>
          <div className="flex gap-2 flex-wrap pb-5">
            {categories.map((category: string) => {
              return (
                <Chip label={category} />
              )
            })}
          </div>

          <Link to={link} className='flex gap-5 flex-wrap'>
            <span className={`text-3xl md:text-4xl text-neutral-700}`}>{title}</span>
            <p className={`text-lg text-neutral-700 ${getWordCount(title) > 10 ? "md:text-xl" : "md:text-2xl"}`}>{excerpt}</p>
          </Link>
        </div>

        <div className="flex-initial flex flex-col lg:flex-row justify-between items-center mt-6 lg:mt-12 gap-4 pt-5">
          <div className="w-64 flex space-x-4">
            <img src={authorPicture} alt={author.name} className="rounded-full w-12 h-12" />
            <div>
              <p className="text-lg font-semibold">{author.name}</p>
              <p className="text-sm font-light">{author.bio}</p>
            </div>
          </div>
          <Link to={link} className="w-full lg:w-auto">
            <PrimaryBtn
              tabIndex={-1}
              className="flex items-center gap-2 shadow-md bg-theme-orange hover:bg-white hover:text-orange-400 w-full lg:w-auto justify-center border-0"
            >
              <span>{t("common.cta.read-more")}</span>
              <ArrowRight size={18} />
            </PrimaryBtn>
          </Link>
        </div>
      </div>
    </div>
  </Container>
}
