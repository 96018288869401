import React, { useState } from "react";
import { NotionArticle, useArticle } from "utils";
import { BLOG_BASE_URL } from "variables";
import { Carousel } from "react-responsive-carousel";
import { ArrowLeft, ArrowRight } from "react-feather";
import { ArticleCard } from "components/common/article-card";

interface ArticlesCarouselProps {
  articles: NotionArticle[];
  title: string;
  subtitle: string;
  type: "default" | "category";
  imageClass?: string;

}

const getChunks = (array: any[], size: number) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const ArticlesCarousel = ({
  articles,
  title,
  subtitle,
  type,
  imageClass = "",
}: ArticlesCarouselProps) => {
  const [slide, setSlide] = useState(0);

  const articleChunks = getChunks(
    articles.map((article) => {
      const internalLink = useArticle({ article }).link;
      const fullInternalLink = `${BLOG_BASE_URL}/${internalLink}`
      const externalLink = useArticle({ article }).externalLink;
      return <ArticleCard
        key={articles[0].id}
        className="max-w-full h-full md:max-w-[384px] hover:bg-neutral-100"
        article={article}
        type="full"
        link={externalLink === "" ? fullInternalLink : externalLink}
        imageClass={imageClass}
      />
    }),
    3
  );

  return (
    <section className="flex flex-col gap-2 relative md:-mb-16">
      <div className="pb-5 flex flex-col gap-2">
        <span className="text-4xl text-blue-700">{title}</span>
        <p className="text-2xl text-neutrals-500">{subtitle}</p>
      </div>

      {type === "default" && (
        <div>
          {/* LARGE SCREENS AND ABOVE */}
          {articleChunks.length > 1 && (
            <button
              disabled={slide === 0}
              className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 top-1/2 left-[-4vw] transform -translate-y-1/2 hidden lg:flex lg:absolute"
              onClick={() => setSlide((prev) => prev - 1)}
            >
              <ArrowLeft />
            </button>
          )}
          {articleChunks.length > 1 && (
            <button
              disabled={slide === articleChunks.length - 1}
              className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 top-1/2 right-[-4vw] transform -translate-y-1/2 hidden lg:flex lg:absolute"
              onClick={() => setSlide((prev) => prev + 1)}
            >
              <ArrowRight />
            </button>
          )}


          <Carousel
            className="hidden lg:block"
            showStatus={false}
            showArrows={false}
            selectedItem={slide}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <button
                  className={`${isSelected ? 'bg-orange-400' : 'bg-neutral-200'} w-3 h-3 rounded-lg m-1`}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                </button>
              );
            }}
          >
            {articleChunks.map((chunk) => (
              <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 h-full pb-16">
                {chunk.map((article) => article)}
              </div>
            ))}
          </Carousel>

          {/* MEDIUM SCREENS AND BELOW */}
          <Carousel
            className="block lg:hidden"
            showStatus={false}
            showArrows={false}
            selectedItem={slide}
            showIndicators={false}
          >
            {articles.map((article) => {
              const internalLink = useArticle({ article }).link;
              const fullInternalLink = `${BLOG_BASE_URL}/${internalLink}`
              const externalLink = useArticle({ article }).externalLink;
              return <ArticleCard
                key={articles[0].id}
                className="max-w-full h-full lg:max-w-[384px]"
                article={article}
                type="full"
                link={externalLink === "" ? fullInternalLink : externalLink}
              />
            })}
          </Carousel>
          <div className="flex gap-4 absolute right-6 -bottom-0">
            <button
              disabled={slide === 0}
              className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 static lg:hidden"
              onClick={() => setSlide((prev) => prev - 1)}
            >
              <ArrowLeft />
            </button>
            <button
              disabled={slide === articles.length - 1}
              className="bg-white drop-shadow-md h-12 w-12 rounded-full flex justify-center items-center disabled:text-black/20 static lg:hidden"
              onClick={() => setSlide((prev) => prev + 1)}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      )
      }

      {
        type === "category" && (
          <div className="grid md:grid-cols-2 md:grid-rows-2 gap-8">
            {articles.map((article, i) => {
              return (
                <ArticleCard
                  key={i}
                  imageClass={i === 0 ? "h-auto sm:h-auto" : imageClass}
                  className={
                    i === 0 ? "row-span-2 max-w-full col-span-1 hover:bg-neutral-100" : "max-w-full hover:bg-neutral-100"
                  }
                  article={article}
                  type={i === 0 ? "full" : "no-image"}
                  link={`${BLOG_BASE_URL}/${useArticle({ article }).link}`}
                />
              );
            })}
          </div>
        )
      }
    </section >
  );
};
